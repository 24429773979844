<template>
  <v-container fluid class="ma-0 pa-0">
    <v-simple-table :style="cardStyle" style="min-height: calc(100vh - 300px)">
      <template v-slot:default>
        <thead>
          <tr>
            <th style="width: 20%" class="text-center title" :style="cardStyle">
              TIME
            </th>
            <th class="text-center title" :style="cardStyle">ADDRESS</th>
            <th style="width: 50px" class="text-center title">
              <v-icon :style="cardStyle"> mdi-bed </v-icon>
            </th>
            <th style="width: 50px" class="text-center title">
              <v-icon :style="cardStyle"> mdi-shower-head </v-icon>
            </th>
            <th style="width: 50px" class="text-center title">
              <v-icon :style="cardStyle"> mdi-car </v-icon>
            </th>
            <th style="width: 30%" class="text-center title" :style="cardStyle">
              PRICE
            </th>
          </tr>
        </thead>
        <tbody>
          <propertyItem
            v-for="(property, index) in properties"
            :key="property.name"
            :index="index"
            :style="index % 2 === 0 ? cardStyle2 : ''"
            :property="property"
          />
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import Vue from "vue";
import propertyItem from "./PropertyItem.vue";
export default Vue.extend({
  name: "PropertyList",
  components: { propertyItem },
  props: {
    properties: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentSlide: 0,
      slideInterval: null,
      imageLoaded: {},
    };
  },
  methods: {
    startSlideShow() {
      this.slideInterval = setInterval(() => {
        this.currentSlide =
          (this.currentSlide + 1) %
          (this.properties[0]?.imagesPreview?.length || 1);
      }, 5000);
    },
    stopSlideShow() {
      clearInterval(this.slideInterval);
      this.slideInterval = null;
    },

    goToDetails(propertyId) {
      console.log(propertyId);
      return;
      // this.$router.push({
      //   name: "PropertyDetails",
      //   params: { id: propertyId },
      // });
    },
  },
  computed: {
    cardStyle() {
      return {
        background: `${window.backgroundColor} !important`,
        color: `${window.fontColor} !important`,
      };
    },
    cardStyle2() {
      return {
        background: `${window.backgroundColor} !important`,
        backgroundColor:
          "rgba(0, 0, 0, 0.03)" /* Black overlay with 20% opacity */,
        color: `${window.fontColor} !important`,
      };
    },
  },
  mounted() {
    this.properties.forEach((property) => {
      this.$set(
        this.imageLoaded,
        property.id,
        Array(property.imagesPreview?.length).fill(false)
      );
    });
    this.startSlideShow();
  },
  beforeDestroy() {
    this.stopSlideShow();
  },
});
</script>

<style scoped>
.carousel-container {
  position: relative;
  height: 250px; /* Increased height for more room for images */
  overflow: hidden;
}

.carousel-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.carousel-slide.active {
  opacity: 1;
}

.v-img {
  object-fit: cover;
  border-radius: 32px; /* More rounded corners for images */
}

.rounded-card {
  border-radius: 32px; /* More rounded corners for cards */
  cursor: pointer;
}

.image-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
</style>
