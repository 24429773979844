<template>
  <v-app>
    <v-main>
      <v-container fill-height v-if="!paginatedProperties?.length">
        <v-row justify="center" class="title-container">
          <h1 v-if="loading">LOADING...</h1>
          <h1 v-else>No properties found</h1>
        </v-row>
      </v-container>
      <v-container v-else fluid class="ma-0 pa-0">
        <div style="background-color: #0d2e5d; color: #fff">
          <v-row
            justify="center"
            class="title-container display-1 ma-0 mx-5 pa-10"
          >
            <h2>{{ media.header }}</h2>
          </v-row>
          <v-row
            v-if="inspectionDate"
            justify="center"
            class="title-container pb-5"
          >
            <h2>{{ inspectionDate }}</h2>
          </v-row>
        </div>
        <router-view :properties="paginatedProperties" />
        <v-row justify="center" class="ma-0">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="10"
            :circle="true"
            :color="'primary'"
            :active-class="'active'"
          ></v-pagination>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { getProperties } from "./fns";
import dayjs from "dayjs";

export default {
  name: "App",
  data() {
    return {
      media: {},
      loading: true,
      allProperties: [],
      page: 1,
      itemsPerPage: 6,
    };
  },
  created() {
    window.loadData = this.loadData;
    // //window.name = ``;
    //window.name =
    //  '{"id":"p2besUfD1WkiYaKIUlnh","email":"rachel.mckay@resbymirvac.com","brand":"vitrinemedia","header":"Open For Inspection","agency_ids":["agentbox-MRCVIC23","agentbox-MRCNSW07","agentbox-HARPRK","agentbox-GRESQU","ourproperty-MRCVIC23"],"listing_count":[0,5],"duration":30,"listing_type":["rental"]}';
    if (window.name) {
      const data = JSON.parse(window.name);
      if (data) this.loadData(data);
    }
    this.setGlobalFontColor("#0d2e5d");
    this.setGlobalBackgroundColor("#ffffff");
    this.listToJS();
  },
  computed: {
    properties() {
      return this.allProperties.filter(
        (item) =>
          item.inspectionDates &&
          item.inspectionDates.includes(this.inspectionDate)
      );
    },
    inspectionDate() {
      let allDates = [];

      // First loop: Collect inspection dates
      for (const property of this.allProperties) {
        if (property.inspectionDates) {
          for (const dateStr of property.inspectionDates) {
            allDates.push({
              original: dateStr,
              parsed: dayjs(dateStr, "ddd, DD MMM YYYY"),
            });
          }
        }
      }

      // Second loop: Filter valid dates
      let validDates = [];
      for (const dateObj of allDates) {
        if (dateObj.parsed.isValid()) {
          validDates.push(dateObj);
        }
      }

      if (!validDates.length) return false;

      const today = dayjs();
      let futureDates = [];
      let pastDates = [];

      // Separate into future and past dates
      for (const dateObj of validDates) {
        if (dateObj.parsed.isAfter(today)) {
          futureDates.push(dateObj);
        } else if (dateObj.parsed.isBefore(today)) {
          pastDates.push(dateObj);
        }
      }

      if (futureDates.length) {
        return futureDates.sort((a, b) => a.parsed - b.parsed)[0].original;
      } else {
        return pastDates.sort((a, b) => b.parsed - a.parsed)[0].original;
      }
    },

    paginatedProperties() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = this.page * this.itemsPerPage;
      return this.properties.slice(start, end);
    },
    pageCount() {
      return Math.ceil(this.properties.length / this.itemsPerPage);
    },
  },

  methods: {
    listToJS() {
      window.addEventListener(
        "message",
        function (event) {
          if (event.data) eval(event.data);
        },
        false
      );
    },
    setGlobalBackgroundColor(color) {
      window.backgroundColor = color;
      this.$vuetify.theme.themes.light.background = color;
      this.$vuetify.theme.themes.dark.background = color;
    },
    setGlobalFontColor(color) {
      window.fontColor = color;
      this.$vuetify.theme.themes.light.primary = color;
      this.$vuetify.theme.themes.light.onBackground = color;
      this.$vuetify.theme.themes.light.color = color;
    },
    loadData(media) {
      this.media = typeof media === "object" ? media : JSON.parse(media);
      // const listing_type = media.listing_type || [];
      // media.listing_type = listing_type.map((l) => l.value);
      this.fetchProperties();
    },

    async fetchAgencyProperties(agencyID) {
      let data = await getProperties(agencyID);
      const list = [];
      for (let i = 0; i < data.length; i++) {
        const inspectionTimes = data[i].inspectionTimes || false;
        if (!inspectionTimes) continue;

        list.push({ ...data[i], imagesPreview: data[i].images.slice(0, 3) });
      }
      return list;
    },

    async fetchProperties() {
      const agency_ids = this.media.agency_ids || [];
      let list = [];

      for (const agencyID of agency_ids) {
        list.push(...(await this.fetchAgencyProperties(agencyID)));
        if (
          list.length >
          this.media.listing_count[0] + this.media.listing_count[1]
        )
          break;
      }

      list = list.filter((item) => this.media.listing_type.includes(item.type));
      this.allProperties = list.slice(
        this.media.listing_count[0],
        this.media.listing_count[1]
      );

      this.updateItemsPerPage();
      this.loading = false;
    },

    autoSwitchPage() {
      setInterval(() => {
        this.page = this.page < this.pageCount ? this.page + 1 : 1;
      }, (this.media?.duration || 30) * 1000);
    },

    updateItemsPerPage() {
      const containerHeight = window.innerHeight;
      return parseInt(containerHeight / 100);
    },
  },
  mounted() {
    if (window.localMediaData) this.loadData(window.localMediaData);
    this.autoSwitchPage();
    window.addEventListener("resize", this.updateItemsPerPage);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateItemsPerPage);
  },
};
</script>
<style>
.v-application {
  font-size: 24px; /* Set default font size for TV readability */
  font-family: Montserrat !important;
}

.v-application {
  background-color: var(--v-background-base) !important;
  color: var(--v-onBackground-base) !important;
}
.title-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.v-card {
  margin-bottom: 16px;
  border-radius: 32px; /* More rounded corners for Material 3 */
}

.v-card-title {
  font-size: 24px; /* Smaller font size for titles to fit more cards */
  font-weight: 500; /* Font weight for M3 */
}

.v-card-subtitle {
  font-size: 20px; /* Smaller font size for subtitles to fit more cards */
  font-weight: 400; /* Font weight for M3 */
}

.v-card-text {
  font-size: 18px; /* Smaller font size for text to fit more cards */
  font-weight: 400; /* Font weight for M3 */
}

.v-btn {
  border-radius: 32px; /* More rounded corners for buttons */
}

.v-application,
.title * {
  font-family: Montserrat !important;
}
</style>
